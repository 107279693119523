.alert {
  background: red;
  box-sizing: border-box;
  padding: 3px 0 3px 0;
  color: #e8e1e0;
}

.umbral-info {
  width: auto;
  padding-right: 15px;
}

.umbral-info:last-child {
  padding-right: 0;
}

.umbral-info__value {
  font-size: 20px;
  padding-bottom: 5px;
  text-align: center;
}

.umbral-info__detail {
  font-size: 11px;
  text-align: center;
}

.pp {
  padding: 20px 20px 20px 20px;
  width: 48%;
  min-width: 250px;
  box-shadow: 0 0 0 1px #e6e6e6;
  margin-bottom: 20px;
}

@media screen and (max-width: 960px) {
  .pp {
    padding: 20px 20px 20px 20px;
    width: 100%;
    min-width: 250px;
    box-shadow: 0 0 0 1px #e6e6e6;
  }
}

.pp:nth-last-child(-n + 2) {
  margin-bottom: 0;
}

.loading {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.flecha {
  max-width: 20px;
  margin: auto;
}
