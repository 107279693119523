a{
    cursor: pointer;
}
.formNotifi{
    width: 100%;
}
.blockNotificaciones{
    display: flex;
    width: 100%;
    height: calc(100vh - 250px);
    min-height: 100%;
}

.blockNotificaciones .blockintnot{
    display: flex;
    width: calc(70% - -24px);
    max-width: calc(70% - -24px);
    flex-direction: column;
    margin-left: 30px;
    border-right: none;
    padding-right: 30px;
}

.int-p p{
    font-weight: 500;
}

.blockNotificaciones .blockintnot:nth-child(1){
    /*border-right: 1px solid #b9b9b9;*/
}

.blockNotificaciones .blockintnot .block-interno .int-p{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.verde_add{
    color: #15b263;
    text-decoration: auto;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    cursor:pointer;
}

.verde_add:hover{
    color: #00794f;
    text-decoration: underline;
}

.verde_boton{
    margin: 0px;
    display: block;
    background: #18b365;
    color: white;
    padding: 6px;
    border-radius: 3px;
    margin-top: 24px;
}

.verde_boton:hover{
    background: #02723a;
    color: white;
}

.block-interno .campo_db{
    display: flex;
    justify-content: space-between;
    margin: 0px 12px;
    margin-bottom: 12px;
    align-items: center;
    border-top: 1px solid #e6e3e3;
}

.block-interno .campo_db.hidden{
    display: none;
}

.block-interno .campo_db label{
    font-size: 12px;
    color: #494949;
}

.block-interno .campo_db p{
    color: #494949;
    font-size: 15px;
    font-weight: 500;
    margin-right: 8px;
    position: relative;
}

.block-interno .campo_db .delcampo{
    color:#b30606;
    text-decoration: auto;
    font-weight: 500;
    font-size: 15px;
}

.block-interno .campo_db .delcampo:hover{
    color: #520101;
    text-decoration: underline;
}

.p_input_nombre input, .p_input_phoneemail input{
    padding: 6px;
    font-size: 14px;
}

.down_cancelar.verde_add {
    margin-top: 25px;
    color: #b30606;
}

.MuiButton-containedWarning{
    color:white;
    background-color: #b30606;
}

.error_span{
    display: none;
}

.error_span.active{
    color: #b30606;
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: -20px;
    display: block;
}

.campo_db .int_name, 
.campo_db .int-phone{
    width: 44%;
}
.delcampo{
    width: 12%;
}

.add-sms.cargando{
    position: relative;
}

.add-sms.cargando::after{
    display: block;
    position: absolute;
    background-color: #ffffffab;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
}

.add-sms.cargando::before{    
    content: '';
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-image: url('loading_gif.gif');
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: center;
}

.info_title{
    font-size: 14px;
    color: #494949;
}

.central-loading{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.central-loading p{
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 30px;
}

.central-loading img{
    width: 70px;
}

input.oculto{
    display: none;
}

input.oculto.visible{
    display: block;
}

.editaz{
    width: max-content;
}

.editaz:hover{
    background-color: rgb(208 208 208 / 39%);
    cursor: pointer;
    position: relative;
}
.editaz:hover::after{
    position: absolute;
    content: 'Editar';
    top: 3px;
    color: #437564;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
}

h3.oculto, div.oculto, p.oculto{
    display: none;
}

.div-cont-input{
    position: relative;
    margin-top: 3px;
    font-size: 13px;
    display: flex;
    max-height: 40px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.div-cont-input input{
    width: 300px;
    padding: 6px;
    border-radius: 2px;
    font-size: 15px;
    border: 1px solid #7d7d7d;
}

.div-botns{
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-botns .verde_boton{
    margin-top: 0px;
    margin-right: 6px;
    height: 30px;
}

.div-botns .down_cancelar.verde_add{
    margin-top: 0px;
    margin-bottom: 0px;
}

.div-cont-input span{
    position: absolute;
    bottom: -16px;
    color: #b71313;
}