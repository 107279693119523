  .grafico_daily{
    width: 100%;
    height: calc(100vh - 320px);
    position: relative;
  }

  .focus circle {
    stroke: #00ab55;
    fill: #00ab55 !important;
    width: 20px;
    height: 20px;
    stroke-width: 3.5px
  }
  
  div#tooltip{
    position:absolute;
    background-color:white;
    padding:5px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    box-shadow: 0px 2px 17px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 2px 17px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 17px -2px rgba(0,0,0,0.75);
    border-radius: 8px;
    font-family: "Public Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(33, 43, 54);
    border: 1px solid #00ab55;
  }

  div#tooltip p{
      font-weight: 900;
  }

  div#tooltip label{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 3px;
  }

  div#tooltip label > span{
      font-weight: 900;
  }

  div#tooltip label > span.circle{
      width:10px;
      height: 10px;
      border-radius: 100%;
      display: block;
      margin-right: 6px;
  }

  div#tooltip label > span.circle.dispositivo{
      background: #00ab55;
  }

  div#tooltip label > span.circle.umbralmax{
    background: #ffc107;
}

div#tooltip label > span.circle.umbralmin{
    background: #1890ff;
}

p.center{
    text-align: center;
}

.h1graphic{
    margin-left: 20px; 
    margin-top: 7px;
    font-family:"Public Sans", sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: black;
}

.h4graphic{
    margin-left: 20px; 
    margin-bottom: 5px; 
    margin-top: 7px;
    font-weight: 400;
    color: #878787;
}

.pdetails{
    margin-left: 20px; margin-bottom: 5px;
    color: #878787;
}

.pdetails span{
    font-weight:700
}

.abajografico{
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 10px;
}

.bold_dispo{
    font-weight: 700;
    color: #00ab55;
    display: block;
    margin-right: 4px;
}

.bold_minimo{
    font-weight: 700;
    color: #1890ff;
    display: block;
    margin-right: 4px;
}

.bold_maximo{
    font-weight: 700;
    color: #ffc107;
    display: block;
    margin-right: 4px;
}

.ejey path.domain{
    color: #9b9b9b;
    opacity: 0.5;
}

.ejex path.domain{
    color: #9b9b9b;
    opacity: 0.5;
}

.flex_wrap{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.legends{
  flex-direction: row;
  display: flex;
  margin-right: 30px;
  font-size: 13px;
  justify-content: center;
  margin-bottom: 20px;
}

.legends p{
  display: flex;
  margin-left: 12px;
  align-items: center;
}

.legends p > span.circle{
  width:10px;
  height: 10px;
  border-radius: 100%;
  display: block;
  margin-right: 6px;
}

.legends p > span.circle.dispositivo, .legends p > span.rectangulo.dispositivo{
  background: #00ab55;
}

.legends p > span.circle.umbralmax, .legends p > span.rectangulo.umbralmax{
background: #ffc107;
}

.legends p > span.circle.umbralmin, .legends p > span.rectangulo.umbralmin{
background: #1890ff;
}

.legends p > span.rectangulo{
  width:15px;
  height: 5px;
  display: block;
  margin-right: 6px;
}

.legends p label{
  font-weight: 700;
}

.sindata{
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  width: 50%;
  text-align: center;
  padding: 20px;
  background: #c2c2c269;
  border-radius: 8px;
}

.sindata.hidden{
  display: none;
}